<template>
  <div>
    <SupportTicketList />
  </div>
</template>

<script>
import SupportTicketList from '@/components/admin/support/SupportTicketList';

export default {
  components: {
    SupportTicketList,
  },
};
</script>

<style lang="scss" scoped>
</style>
